import { Container, Row } from "react-dom"
export default function Home(){
    return (
        <div className="flex flex-col bg-white h-screen w-screen pt-10 md:pt-0 md:justify-center items-center">
            {/* text-teal-500 */}
            <div className="mb-7 flex flex-col text-center tracking-wider text-4xl">
                <span className="uppercase font-coming-soon">Full website coming soon!</span>
            </div>
            <img className="h-2/5 md:h-1/3" src='/banner.png' alt="" />
            <div className="mt-10 flex flex-col text-center">
                <span className="tracking-tighter md:tracking-normal">Contact@ThreeStudio.NYC</span>
                <span>646.243.0328</span>
            </div>
            <div className="mt-10 flex flex-col text-center">
                <span>We are currently seeking talented and motivated individuals, junior designers and senior architects.</span>
                <span>Please send your resume and portfolio to: <a className="underline text-blue-600 hover:text-blue-800" href="mailto:someone@yoursite.com?subject=Career opportunity">Contact@ThreeStudio.NYC</a>. Email not larger than 5MB.</span>
            </div>
        </div>
    )
}